<template>
  <gw-dialog
    v-model="dialog"
    :loading="loading"
    @open:dialog="onOpen"
    @close:dialog="onClose"
  >
    <template #title>
      <v-card-title>
        <span class="text-h5">{{ $t("invoice.to.email") }}</span>
      </v-card-title>
    </template>

    <v-container>
      <v-row>
        <v-col cols="12">
          <form-field-email
            v-model="sEmail"
            multiple
            required
            :disabled="loading"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox class="xl-checkbox" v-model="saveEmails">
            <template #label
              >Guardar listado de email/s para nuevos reenvios</template
            >
          </v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <template #prepend-actions>
      <v-btn
        :loading="loading"
        :disabled="!canSend"
        color="primary"
        text
        @click="onSend"
        v-text="$t('send')"
      />
    </template>
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import GwDialog from "@/components/common/GwDialog.vue";
import type { InvoiceData } from "@planetadeleste/vue-mc-gw";
import { Invoice } from "@planetadeleste/vue-mc-gw";
import type { FirmData } from "@planetadeleste/vue-mc-gw/src/types";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { isEmpty, now } from "lodash";

@Component({
  components: { GwDialog },
})
export default class InvoiceSendToEmail extends Vue {
  @VModel({ type: Boolean, default: false }) dialog!: boolean;
  @Prop(Object) readonly invoice!: InvoiceData;

  loading = false;
  saveEmails = true;
  obInvoice: Invoice | null = null;
  sEmail: string | null = null;

  get validEmails() {
    const reg =
      /^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(,\s{0,1}){0,1})+$/;
    return reg.test(this.sEmail || "");
  }

  get canSend() {
    return !isEmpty(this.sEmail) && this.validEmails;
  }

  get emailRules() {
    return isEmpty(this.sEmail) || this.validEmails
      ? []
      : ["Formato de correo/s invalido/s. Recuerde separarlos por coma."];
  }

  onClose() {
    this.sEmail = null;
  }

  async onOpen() {
    //Se quita para forzar el update de invoice
    //if (!this.invoice || !this.invoice.id) {
    //  return;
    //}

    this.loading = true;
    this.obInvoice = new Invoice({ id: this.invoice.id });
    await this.obInvoice.fetch({
      params: { forceupdate: now() },
    });

    const obCustomer: FirmData = this.obInvoice.get("customer_firm");
    if (obCustomer && obCustomer.email) {
      this.sEmail = obCustomer.email;
    }

    if (!this.sEmail) {
      this.sEmail = this.obInvoice.customer.firm.email;
    }
    this.loading = false;
  }

  async onSend() {
    if (!this.sEmail || !this.obInvoice || !this.obInvoice.id) {
      return;
    }

    this.loading = true;
    const obData = {
      id: this.obInvoice.id,
      email: this.sEmail,
      saveList: this.saveEmails,
    };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.send_email", obData),
      method: "POST",
      data: obData,
    };
    const obResponse = await this.obInvoice.createRequest(obConfig).send();
    this.loading = false;

    if (obResponse) {
      const obResponseData = obResponse.getData();
      const sMessage = obResponseData.message;

      if (sMessage) {
        if (!obResponseData.status) {
          this.$toast.error(sMessage);

          return;
        } else {
          this.$toast.success(sMessage);
        }
      } else if (obResponseData.status) {
        this.$toast.success(this.$t("invoice.email.sent"));
      }
    }

    this.sEmail = null;

    // Close dialog
    this.$emit("input", false);
  }
}
</script>
